@import "../../../node_modules/bootstrap/scss/functions";



$sml-body-bg: #ffffff;
$sml-body-color: #1B1B1B;

$sml-primary: #6417fb;
$sml-primary-dark: #410AAB;
$sml-secondary: #de376a;
$sml-accent: #A428AF;
$sml-box-shadow: 4px 4px 40px rgba(169, 169, 169, 0.25);

$sml-gradient: linear-gradient(90deg, $sml-primary 0%, #de376a 100%);
$sml-gradient-text: linear-gradient(90deg, $sml-primary 0%, #de376a 100%);
$sml-gradient-y: linear-gradient(180deg, $sml-primary 0%, #de376a 100%);
$sml-gradient-radial: radial-gradient(50% 50%, $sml-primary 50%, #de376a 100%);
$sml-white-gradient: linear-gradient(180deg, rgba(222, 55, 106, 0.14) 0%, rgba(222, 55, 106, 0.03) 100%);
$sml-white2-gradient: linear-gradient(180deg, #FFFFFF 0%, #FFF2F6 100%);
$sml-strong-gradient: linear-gradient(180deg, $sml-primary 0%, $sml-primary-dark 87.43%);;

$sml-font-family-base: var(--font-rubik), 'Arial', sans-serif;

// Bootstrap
$body-color: $sml-body-color;
$nav-link-color: $body-color;
$navbar-light-color: $nav-link-color;
$border-radius: .575rem;
$border-radius-sm: .65rem;
$border-radius-lg: .7rem;
$border-radius-xl: 1.2rem;
$border-radius-2xl: 2.2rem;
$font-family-base: $sml-font-family-base;
$primary: $sml-primary;
$primary-dark: darken($primary, 20);
$secondary: $sml-secondary;
$body-bg: $sml-body-bg;

$border-width: 1.4px;
$btn-padding-x: 1.2rem;
$btn-padding-x-lg: 1.6rem;
$btn-padding-y-lg: 0.8rem;

@import "../../../node_modules/bootstrap/scss/variables";

$nav-link-font-size: $font-size-lg;
$btn-font-weight: $font-weight-semibold;
$custom-colors: (
        'accent': $sml-accent,
        'primary-dark': $sml-primary-dark,
        'info': lighten($sml-accent, 20),
);
$theme-colors: map-merge($theme-colors, $custom-colors);

$zindex-carousel-full-page: $zindex-dropdown + 1;
$zindex-hand-phone: $zindex-dropdown + 5;
$zindex-mobile-screenshots: $zindex-hand-phone;
$zindex-dark-mode-switch: $zindex-carousel-full-page + 50;