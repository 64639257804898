@import 'stylekit';
@import 'bootstrap';
@import 'variables';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';


html {
  font-size: 12px;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 2 * ((100vw - 320px) / 680));
  }
}

@media screen and (min-width: 1000px) {
  html {
    font-size: 14px;
  }
}

#__next,
body,
html {
  height: 100%;
  width: 100%;
}

.cookie-consent {
  z-index: 2001;
  position: fixed;
  left: 100px;
  right: 100px;
  bottom: 10px;
  @include media-breakpoint-down('md') {
    left: 15px;
    right: 15px;
  }
}

@import 'themes/allThemes';


