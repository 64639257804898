.smartlink-widget-link {
  .btn {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    max-width: 300px;

    .smartlink-widget-link-text {
      margin-right: 10px;
      margin-left: 10px;
      flex: 1;
    }
  }

  .smartlink-widget-link-ms-logocolor-light {
    display: none;
  }
  .smartlink-widget-link-ms-logo-dark {
    display: none;
  }
  .smartlink-widget-link-ms-logo-light {
    display: none;
  }
}