@import './theme-variables';

@mixin smartlink-widget-edit-mode-hover {
  .smartlink-widget-content {
    border: 2px dotted $theme-button-color;
  }

  .smartlink-widget-edit-mode-overlay {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 4000;

    .btn {
      width: 40px;
      height: 40px;
      line-height: 40px;
      padding: 0;
      border-radius: 0;

      &:not(:hover) {
        border-color: white;
        background: white;
      }

      &:first-child {
        border-radius: 8px 8px 0 0;
      }

      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }
  }

}

.smartlink {
  &.smartlink-normal {
    .smartlink-widgets {
      width: 100%;
      min-width: auto;

      @include media-breakpoint-up('md') {
        min-width: 400px;
        width: auto;
      }
    }
  }

  &.smartlink-theme-preview-generation {
    .smartlink-background {
      display: none;
    }

    .smartlink-content {
      background-position: center center;
      background-size: cover;
    }
  }

  flex: 1;

  .smartlink-background {
    .smartlink-background-inner {
      background-position: center center;
      background-size: cover;
      width: 100%;
      height: 100%;
      margin: auto;
      position: fixed;
    }

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }

  .smartlink-content {
    z-index: 2;
    min-height: 100%;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    .smartlink-widgets {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      padding-bottom: 2rem;

      .smartlink-widget {
        &:first-child {
          padding-top: 40px;
        }

        &:last-child {
          padding-bottom: 40px;
        }

        padding: 10px 30px 10px 30px;
        margin-bottom: 4px;
        width: 100%;

        .smartlink-widget-content {
          position: relative;
          border: 2px dashed transparent;
        }

        &.smartlink-widget-edit-mode {
          &:first-child {
            padding-top: 30px;
          }

          &:last-child {
            padding-bottom: 30px;
          }

          padding: 0 15px 0 15px;

          .smartlink-widget-edit-mode-overlay {
            display: none;
          }

          .smartlink-widget-content {
            border-radius: 10px;
            padding: 10px 15px 10px 15px;
          }

          &:hover {
            @include smartlink-widget-edit-mode-hover;
          }

          @media (hover: none) {
            & {
              @include smartlink-widget-edit-mode-hover;
              .smartlink-widget-content {
                margin-bottom: 50px;
              }
            }
          }
        }
      }
    }
  }
}

a.simple-footer-logo-container {
  font-weight: 300;
  text-transform: lowercase;
  margin-bottom: 0;
  font-size: 0.8rem;
  text-decoration: none;
  color: $theme-footer-text-color;
}