$theme-name: 'dark';

$theme-font-family: var(--font-genos), 'Arial', sans-serif;
$theme-font-family-body: var(--font-inter), 'Arial', sans-serif;
$theme-button-color: #A42222;
$theme-button-active-color: #1f1f1f;
$theme-body-color: #ffffff;
$theme-background-color: #1f1f1f;

$theme-social-link-color: #ffffff;
$theme-social-link-border: null;
$theme-social-link-background: transparent;
$theme-footer-text-color: #ffffff;

$theme-widget-info-title-font-size: 3.5rem;
$theme-widget-info-normal-font-size: 1.5rem;
$theme-widget-info-small-font-size: 1rem;

$theme-widget-profile-font-size: 2rem;
$theme-widget-avatar-border-color: #1B1B1B;

@import './smartLinks/theme';


.theme-#{$theme-name} {
  .btn-outline-theme {
    .smartlink-widget-link-ms-logocolor-dark {
      display: none;
    }

    .smartlink-widget-link-ms-logo-light {
      display: none;
    }

    .smartlink-widget-link-ms-logo-dark {
      display: inline-block;
    }

    @include button-variant(
                    $theme-button-color,
                    $theme-button-color,
                    $theme-body-color
    );

    border-radius: 0;

    &:hover {
      color: $theme-body-color;
    }
  }

  .smartlink-content .smartlink-widgets {

    .smartlink-widget {
      .smartlink-social-links {
        justify-content: start;

        .smartlink-social-links-link {
          justify-content: start;
          margin-left: 0;
        }
      }

      .smartlink-widget-info {
        text-align: left;
      }

      .smartlink-widget-link {
        .btn {
          justify-content: center;
          .smartlink-widget-link-text {
            flex: none;
          }
        }
      }

      .smartlink-artist {
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}