@import './../theme-variables';

.smartlink {
  .smartlink-content {
    .smartlink-artist {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .smartlink-artist-avatar {
        height: 100px;
        width: 100px;
        flex-shrink: 0;
        background-position: center center;
        background-size: cover;
        border-radius: 50%;
        border: 1px solid $theme-widget-avatar-border-color;
        margin-right: 12px;
      }

      h1 {
        font-size: $theme-widget-profile-font-size;
        font-family: $theme-font-family;
        font-weight: 500;
        margin: 0;
      }
    }
  }
}


