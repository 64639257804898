$theme-name: 'deep';

$theme-font-family: var(--font-michroma), 'Arial', sans-serif;
$theme-font-family-body: var(--font-inter), 'Arial', sans-serif;
$theme-button-color: #ffffff;
$theme-button-active-color: #1F212B;
$theme-body-color: #ffffff;
$theme-background-color: #1F212B;

$theme-social-link-color: #ffffff;
$theme-social-link-border: #ffffff;
$theme-social-link-background: transparent;
$theme-footer-text-color: #ffffff;

$theme-widget-info-title-font-size: 3.5rem;
$theme-widget-info-normal-font-size: 1.5rem;
$theme-widget-info-small-font-size: 1rem;

$theme-widget-profile-font-size: 2rem;
$theme-widget-avatar-border-color: #1B1B1B;

@import './smartLinks/theme';


.theme-#{$theme-name} {
  .btn-outline-theme {
    .smartlink-widget-link-ms-logocolor-dark {
      display: none;
    }
    .smartlink-widget-link-ms-logo-light {
      display: none;
    }
    .smartlink-widget-link-ms-logo-dark {
      display: inline-block;
    }

    &:hover {
      background-color: $theme-button-color;

      .smartlink-widget-link-ms-logo-light {
        display: inline-block;
      }
      .smartlink-widget-link-ms-logo-dark {
        display: none;
      }
    }
  }

  .smartlink-content .smartlink-widgets {
    .smartlink-widget {
      .smartlink-social-links {
        justify-content: flex-start;
        .smartlink-social-links-link {
          justify-content: flex-start;
          margin-left: 0;
        }
      }

      .smartlink-widget-link {
        .btn {
          border-radius: 0;
          box-shadow: 2px 2px 0 1px rgba(255, 255, 255, 0.4);
        }
      }

      .smartlink-widget-info {
        text-align: left;
      }

      .smartlink-artist {
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}