$theme-name: 'chill';

$theme-font-family: var(--font-rufina), 'Arial', sans-serif;
$theme-font-family-body: var(--font-maven-pro), 'Arial', sans-serif;
$theme-button-color: #000000;
$theme-button-active-color: #FFFFFF;
$theme-body-color: #000000;
$theme-background-color: #E7D5FE;

$theme-social-link-color: #000000;
$theme-social-link-background: transparent;
$theme-footer-text-color: #000000;

$theme-widget-info-title-font-size: 3.5rem;
$theme-widget-info-normal-font-size: 1.5rem;
$theme-widget-info-small-font-size: 1rem;

$theme-widget-profile-font-size: 2rem;
$theme-widget-avatar-border-color: #1B1B1B;

@import './smartLinks/theme';
@import './smartLinks/theme';

.theme-#{$theme-name} {
  .smartlink {
    .btn-outline-theme {
      font-weight: 500;
      text-transform: uppercase;

      .smartlink-widget-link-ms-logocolor-dark {
        display: none;
      }

      .smartlink-widget-link-ms-logo-light {
        display: inline-block;
      }

      .smartlink-widget-link-ms-logo-dark {
        display: none;
      }

      &:hover {
        background-color: $theme-button-color;

        .smartlink-widget-link-ms-logo-light {
          display: none;
        }

        .smartlink-widget-link-ms-logo-dark {
          display: inline-block;
        }
      }

      .smartlink-widget-link-text {
        flex: 1;
      }
    }

    .smartlink-content {
      .smartlink-content-left {
        order: 2;
        @include media-breakpoint-up('md') {
          padding-left: 3rem;
        }
      }

      .smartlink-widgets {
        .smartlink-widget {
          .smartlink-social-links {
            justify-content: flex-start;

            .smartlink-social-links-link {
              justify-content: flex-start;
              margin-left: 0;
            }
          }

          .smartlink-artist {
            justify-content: flex-start;
            align-items: center;
          }

          .smartlink-widget-link {
            .btn {
              border-radius: 0;
            }
          }

          .smartlink-widget-info {
            text-align: left;

            .smartlink-widget-info-text {
              margin-right: 10px;
            }

            &.widget-info-title::after {
              content: url('/statics/themes/chill/chill-title-image.svg');
            }
          }

          .smartlink-widget-image {
            padding: 20px;
            border: 1px solid rgba(0, 0, 0, 0.5);
          }

          .smartlink-artist {
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }
  }
}