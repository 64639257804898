@import './../theme-variables';

.smartlink-widget-info {
  text-align: center;
  color: $theme-body-color;


  &.widget-info-normal {
    font-size: $theme-widget-info-normal-font-size;
    font-weight: 300;
  }

  &.widget-info-small {
    font-size: $theme-widget-info-small-font-size;
    font-weight: 300;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}


h1.smartlink-widget-info.widget-info-title {
  font-size: $theme-widget-info-title-font-size;
  font-family: $theme-font-family;
  font-weight: 500;
}