@use "sass:color";
@import './theme-variables';

.theme-#{$theme-name} {
  background: $theme-background-color;
  color: $theme-body-color;

  @import 'src/assets/styles/themes/smartLinks/widgets/artist';
  @import './socialLinks';
  @import './smartLinks';
  @import './widgets/info';
  @import './widgets/image';
  @import './widgets/link';

  font-family: $theme-font-family-body;
  .btn-outline-theme {
    @include button-outline-variant(
                    $theme-button-color,
                    $theme-button-active-color,
                    $theme-button-color,
                    $theme-button-color,
                    $theme-button-active-color
    );
    color: $theme-body-color;
    &:hover {
      color: $theme-button-active-color;
    }
  }
}