@import './theme-variables';

.smartlink {
  .smartlink-social-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .smartlink-social-links-link {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 10px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-decoration: none;
      border-radius: 50%;
      font-size: 1.5rem;
      color: $theme-body-color;
      color: $theme-social-link-color !important;
      background-color: $theme-social-link-background !important;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}