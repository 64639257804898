$theme-name: 'loli';

$theme-font-family: var(--font-michroma), 'Arial', sans-serif;
$theme-font-family-body: var(--font-maven-pro), 'Arial', sans-serif;
$theme-button-color: rgba(255, 255, 255, 0.8);
$theme-button-active-color: #1F212B;
$theme-body-color: rgba(255, 255, 255, 0.8);
$theme-background-color: #1C0D41;

$theme-social-link-color: rgba(255, 255, 255, 0.8);
$theme-social-link-border: rgba(255, 255, 255, 0.8);
$theme-social-link-background: transparent;
$theme-footer-text-color: rgba(255, 255, 255, 0.8);

$theme-widget-info-title-font-size: 3.5rem;
$theme-widget-info-normal-font-size: 1.5rem;
$theme-widget-info-small-font-size: 1rem;

$theme-widget-profile-font-size: 2rem;
$theme-widget-avatar-border-color: #1B1B1B;

@import './smartLinks/theme';


.theme-#{$theme-name} {
  .btn-outline-theme {
    border-radius: 0;
    text-transform: uppercase;

    .smartlink-widget-link-ms-logocolor-dark {
      display: none;
    }

    .smartlink-widget-link-ms-logo-light {
      display: none;
    }

    .smartlink-widget-link-ms-logo-dark {
      display: inline-block;
    }

    &:hover {
      background-color: $theme-button-color;

      .smartlink-widget-link-ms-logo-light {
        display: inline-block;
      }

      .smartlink-widget-link-ms-logo-dark {
        display: none;
      }
    }
  }

  .smartlink-content {

    .smartlink-content-left {
      order: 2;
      @include media-breakpoint-up('md') {
        padding-left: 3rem;
      }
    }

    .smartlink-widgets {
      background: linear-gradient(180deg, rgba(72, 52, 129, 0.28) 0%, rgba(24, 12, 57, 0.42) 100%);

      .smartlink-widget {
        .smartlink-social-links {
          justify-content: start;

          .smartlink-social-links-link {
            justify-content: start;
            margin-left: 0;
          }
        }

        .smartlink-widget-info {
          text-align: left;
        }

        .smartlink-artist {
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}